import React from 'react';
import AuthRosterPage from './AuthRosterPage/AuthRosterPage';
import PublicRosterPage from './PublicRosterPage/PublicRosterPage';

const RosterPage = ({ isLoggedIn }) => {
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = false;
  }

  if (document.getElementsByClassName('sidebar-open')[0]) {
    document.getElementsByClassName('sidebar-open')[0].hidden = false;
  }
  return isLoggedIn ? <AuthRosterPage /> : <PublicRosterPage />;
};

export default RosterPage;
