import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getDepartmentsList } from '../../../redux/actions/department';
import { uploadFile, uploadInstruction } from '../../../redux/actions/file';
import { getMatesList } from '../../../redux/actions/mates';
import { getOrganisationsList } from '../../../redux/actions/organisations';
import {
  selectCurrentOrganisation,
  selectOrganisations,
} from '../../../redux/selectors/organisations';
import {
  ORGANISATION_ID_NON_OLYMPIC_TRAINERS,
  ORGANISATION_ID_OLYMPIC_TRAINERS,
} from '../../../constants/const';
import RosterService from '../../../redux/services/roster.service';

const Form = (props) => {
  const [ready, setReady] = useState(false);
  const { fullList } = useSelector((state) => state);
  const organisations = useSelector(selectOrganisations);
  const currentOrganisation = useSelector(selectCurrentOrganisation);
  const { departments } = useSelector((state) => state);
  const { mates } = useSelector((state) => state);
  const [testing, setTesting] = useState(false);
  const [errorsRoster, setErrorsRoster] = useState(false);

  const { auth } = useSelector((state) => state);
  const [ind, setInd] = useState('');
  const dispatch = useDispatch();
  const [sports, setSports] = useState([]);
  const [prevForm, setPrevForm] = useState({ ...props.form });
  const [parcialForm, setParcialForm] = useState({ ...props.form });

  const resetFilter = (e) => {
    e.preventDefault();
    props.setForm([]);
  };
  const formSubmit = (e) => {
    e.preventDefault();
    if (props.reas) {
      props.rejectConfirm();
    } else if (props.edit) {
      if (props.edit && props.editOrg) {
        props.saveEditChangeOrg();
      } else {
        props.saveEditChange();
      }
    } else if (props.add) {
      props.saveAddChange();
    } else {
      props.saveAddChange();
    }
  };
  const getDepartments = useCallback(async () => {
    await dispatch(getDepartmentsList(currentOrganisation.id))
      .then((response) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [currentOrganisation]);

  const getInvoFederations = useCallback(async () => {
    const filter = { type: '9' };
    await dispatch(getOrganisationsList({ filter }))
      .then((response) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  const getTrainers = useCallback(async () => {
    let element = '';
    if (props.form && props.form.sportCategoryType === 0) {
      element = ORGANISATION_ID_OLYMPIC_TRAINERS;
    } else if (props.form.sportCategoryType === 1) {
      element = ORGANISATION_ID_NON_OLYMPIC_TRAINERS;
    }
    await dispatch(getMatesList(element))
      .then((response) => {
        setReady(true);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [props.form]);

  const closeClick = () => {
    props.setForm(prevForm);
    props.setFormActive(false);
  };

  const changeSelectMultiple = (e) => {
    let arr = [...sports];

    if (arr.indexOf(e.target.value) >= 0) {
      arr.splice(arr.indexOf(e.target.value), 1);
    } else {
      arr.push(e.target.value);
    }

    setSports(arr);
    props.setForm({ ...props.form, ['sports']: arr });
  };
  const changeSelectFirst = (event) => {
    setInd(event.target.selectedOptions[0].accessKey);
    props.setForm({ ...props.form, [event.target.name]: event.target.selectedOptions[0].id });
  };
  const changeSelect = (event) => {
    props.setForm({ ...props.form, [event.target.name]: event.target.selectedOptions[0].id });
  };

  const changeSelectSel = (event) => {
    const selectedOption = event.target.selectedOptions[0];
    const updatedData = {
      ...props.form, // Создаем новый объект, чтобы избежать мутации
      [`${event.target.name}id`]: selectedOption.id,
      [event.target.name]: selectedOption.value,
      comment: selectedOption.value, // Устанавливаем значение для textarea
    };
  
    setParcialForm(updatedData); // Обновляем локальное состояние
    props.setForm(updatedData);  // Вызываем функцию обновления в родителе
  };

  const changeSelectFilterCheck =(event)=>{
    let arr = [];
    if (props.form.length > 0) {
      arr = [...props.form];
    }
    let data = {
      id: event.target.accessKey,
      name: event.target.name,
      value: event.target.checked ?'1':'0',
    };

    if (arr[event.target.accessKey]) {
      arr[event.target.accessKey] = data;
    } else {
      arr[event.target.accessKey] = data;
    }
    props.setForm(arr);
  }
  const changeSelectFilter = (event) => {
    let arr = [];
    if (props.form.length > 0) {
      arr = [...props.form];
    }
    if (event.target.name === 'sportCategoryType') {
      setInd(event.target.value);
    }
    let data = {
      id: event.target.accessKey,
      name: event.target.name,
      value: event.target.selectedOptions[0].value,
    };

    if (arr[event.target.accessKey]) {
      arr[event.target.accessKey] = data;
    } else {
      arr[event.target.accessKey] = data;
    }
    props.setForm(arr);
  };

  const changeHandler = (event) => {
    if (props.filterCert || props.filterApp) {
      let arr = [];
      if (props.form.length > 0) {
        arr = [...props.form];
      }
      let data = {
        id: event.target.accessKey,
        name: event.target.name,
        value: event.target.value,
      };

      if (arr[event.target.accessKey]) {
        arr[event.target.accessKey] = data;
      } else {
        arr[event.target.accessKey] = data;
      }
      props.setForm(arr);
      return;
    }

    if (props.setError) {
      props.setError([]);
    }
    props.setForm({ ...props.form, [event.target.name]: event.target.value });
  };
  const setCode =(event)=>{
    props.setForm({ ...props.form, [event.target.name]: event.target.checked });
  }

  const changeHandlerHan = (event) => {
    setParcialForm({ ...props.form, [event.target.name]: event.target.value });
    props.setForm({ ...props.form, [event.target.name]: event.target.value });
  }
  
  ///////////////////////////////////////////////////
  ///////////////////////////////////////////////////

  const changeHandlerAchievements = (event) => {
    let arr = [];
    let val = {
      ...parcialForm.achievements[parcialForm.index],
      [event.target.name]: event.target.value,
    };

    arr = [...parcialForm.achievements];
    if (arr[parcialForm.index]) {
      arr[parcialForm.index] = val;
    } else {
      arr.push(val);
    }
    setParcialForm({ ...parcialForm, achievements: arr });
  };
  const changeSelectAchievements = (event) => {
    let arr = [];
    let val = {
      ...parcialForm.achievements[parcialForm.index],
      [event.target.name]: event.target.selectedOptions[0].id,
      [event.target.name+'Title']: event.target.selectedOptions[0].value,
    };
    arr = [...parcialForm.achievements];
    if (arr[parcialForm.index]) {
      arr[parcialForm.index] = val;
    } else {
      arr.push(val);
    }
    debugger
    setParcialForm({ ...parcialForm, achievements: arr });
  };
  const achievmentsSubmit = (e) => {
    e.preventDefault();
    props.setForm(parcialForm);
    props.saveAddChange(parcialForm);
  };
  const onInstructionUpload = useCallback(
    async (event) => {
      event.preventDefault();
      if (event.target.files[0]) {
        await dispatch(uploadInstruction(event.target.files[0]))
          .then((response) => {
            let obj = {};
            if (event.target.id == 'url') {
              obj = {
                ...props.form,
                [event.target.id]: response,
                scanName: event.target.files[0].name,
              };
            } else {
              obj = {
                ...props.form,
                [event.target.id]: response,
                videoName: event.target.files[0].name,
              };
            }

            props.setForm(obj);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [props.form],
  );
  const deleteInstructionHandler = (e) => {
    e.preventDefault();
    props.setForm({ ...props.form, [e.target.id]: '' });
  };

  const onRosterUpload = useCallback(
    async (event) => {
      event.preventDefault();
      if (event.target.files[0]) {
        await RosterService.uploadRoster(event.target.files[0])
          .then((response) => {
            let obj = { ...props.form, scan: response, scanName: event.target.files[0].name };
            props.setForm(obj);
            setTesting(true);
            RosterService.testRosterFile(props.subtab).then((res) => {
              setTesting(false);
              console.log('testRosterFile success');
              setErrorsRoster(res);
            });
          })
          .catch((e) => {
            setTesting(false);
            console.log('testRosterFile fail');
            setErrorsRoster(e);
            // console.log(e);
          });
      }
    },
    [props.form],
  );

  const onDocumentUpload = useCallback(
    async (event) => {
      event.preventDefault();
      if (event.target.files[0]) {
        
        await dispatch(uploadFile(event.target.files[0]))
          .then((response) => {
            let obj = { ...props.form, [event.target.id]: response, [event.target.id+'Name']: event.target.files[0].name };
            props.setForm(obj);
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
    [props.form],
  );

  const deleteHandler = (e) => {
    e.preventDefault();
    if(e.target.id){
      props.setForm({ ...props.form, [e.target.id]: '' });
    }else{
      props.setForm({ ...props.form, scan: '' });
    }
  };

  if (props.reas) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відмова в реєстрації Організації</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відмови</span>
              <textarea
                autoFocus={true}
                rows="20"
                cols="100"
                style={{ resize: 'true', fontSize: '14px' }}
                name="reason"
                onChange={changeHandler}
                value={props.form.reason}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.declineReg) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Віхилення заявки РегіонСпортом</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відхилення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[16].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відхилення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  }else if (props.decline) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Повернення заявки до суб’єкта подання</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина повернення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } 
  else if (props.depDecline) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відхилення заявки</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відхилення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[16].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відхилення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.invoFederation) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Узгодження заявки</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Коментар до узгодження</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.invoDecline) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відхилення заявки</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відхилення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[16].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відхилення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.invoSelect) {
    if (!ready) {
      getInvoFederations();
    }
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Оберіть відділ</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Відділ департаменту</span>
              <select name="departmentId" autoFocus={true} onChange={changeSelect}>
                <option></option>
                {!!organisations.length &&
                  organisations.map((elem) => {
                    return (
                      <option
                        selected={props.comment && props.comment.departmentId === elem.id}
                        value={elem.id}
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.name}
                      </option>
                    );
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Обрати
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.depCommision) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправлення заявки на комісію</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[17].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.awardDec) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відхилення заявки</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відхилення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[16].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відхилення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.order) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Оберіть підписанта наказу</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>ПІБ</span>
              <select name="signer1Name" autoFocus={true} onChange={changeSelect}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[8].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.signer1Name ===
                            elem.lastName +
                              ' ' +
                              elem.firstName[0] +
                              '.' +
                              elem.secondName[0] +
                              '. ' +
                              elem.position
                        }
                        value={
                          elem.lastName +
                          ' ' +
                          elem.firstName[0] +
                          '.' +
                          elem.secondName[0] +
                          '. ' +
                          elem.position
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.lastName +
                          ' ' +
                          elem.firstName[0] +
                          '.' +
                          elem.secondName[0] +
                          '. ' +
                          elem.position}
                      </option>
                    );
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.printCer) {
    let newArr = [];
    props.selectDep.forEach((el) => {
      if (newArr.indexOf(el.department) === -1) {
        newArr.push(el.department);
      }
    });
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Вибір підписантів посвідчення</h1>
          <form onSubmit={formSubmit}>
            {newArr.map((el) => {
              return (
                <label className="labelText" key={el}>
                  <span>
                    ПІБ підписанта {el === '0' && 'олімпійського департаменту'}{' '}
                    {el === '1' && 'неолімпійського департаменту'}{' '}
                    {el === '2' && 'УкрЦентру "Інваспорт";'}
                  </span>
                  <select name={'signer2Name' + el} onChange={changeSelect}>
                    <option value=""></option>
                    {fullList &&
                      fullList.length > 0 &&
                      fullList[8].map((elem) => {
                        return (
                          <option
                            selected={
                              props.form &&
                              props.form.signer1Name ===
                                elem.lastName +
                                  ' ' +
                                  elem.firstName[0] +
                                  '.' +
                                  elem.secondName[0] +
                                  '. ' +
                                  elem.position
                            }
                            value={
                              elem.lastName +
                              ' ' +
                              elem.firstName[0] +
                              '.' +
                              elem.secondName[0] +
                              '. ' +
                              elem.position
                            }
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.lastName +
                              ' ' +
                              elem.firstName[0] +
                              '.' +
                              elem.secondName[0] +
                              '. ' +
                              elem.position}
                          </option>
                        );
                      })}
                  </select>
                </label>
              );
            })}
            <button className="btn-primary text-btn-primary" type="submit">
              Видати
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.giveCer) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Кому видано</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>ПІБ (та посада) особи, якій видано посвідчення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true', fontSize: '14px' }}
                name="recipientName"
                onChange={changeHandler}
                value={props.form.recipientName}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Видати
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.awardRet) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправлення заявки до керівника профільного департаменту</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.awardCom) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Узгодження комісією</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Узгодження</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <label className="labelText">
                {!props.form.scan && (
                  <label className="icon-text-btn-secondary dow" htmlFor="scan">
                    Завантажити Висновок комісії
                  </label>
                )}
                <input
                  accept="application/pdf"
                  type="file"
                  id="scan"
                  name="scan"
                  className="documentInput"
                  onChange={onDocumentUpload}
                />
                {props.form.scan && (
                  <a href={props.form.scan} className="document-link">
                    {props.form.scanName}
                    <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                  </a>
                )}
                <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                  (pdf одним файлом, до 10 МБайт)
                </span>
              </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.departmentHeadRet) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправлення заявки до співробітника відділу</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.departmentHeadDec) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відмова в присвоєнні звання</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відмови</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[16].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відмови</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.orderReg) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Реєстрування наказу</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Номер наказу</span>
              <input
                autoFocus={true}
                type="text"
                name="orderNumber"
                onChange={changeHandler}
                value={props.form.orderNumber}
              />
            </label>
            <label className="labelText">
              <span>Дата підписання наказу</span>
              <input
                type="date"
                max={new Date().toISOString().split('T')[0]}
                name="assignDate"
                onChange={changeHandler}
                value={props.form.assignDate}
              />
            </label>

            <label className="labelText">
                {!props.form.orderUrl && (
                  <label className="icon-text-btn-secondary dow" htmlFor="orderUrl">
                    Прикріпити підписаний наказ
                  </label>
                )}
                <input
                  accept="application/pdf"
                  type="file"
                  id="orderUrl"
                  name="orderUrl"
                  className="documentInput"
                  onChange={onDocumentUpload}
                />
                {props.form.orderUrl && (
                  <a href={props.form.orderUrl} className="document-link">
                    {props.form.orderUrlName}
                    <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                  </a>
                )}
                <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                  (pdf одним файлом, до 10 МБайт)
                </span>
              </label>


            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } 
  else if(props.orderRegNumber){
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Реєстрування наказу</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
                {!props.form.orderUrl && (
                  <label className="icon-text-btn-secondary dow" htmlFor="orderUrl">
                    Прикріпити підписаний наказ
                  </label>
                )}
                <input
                  accept="application/pdf"
                  type="file"
                  id="orderUrl"
                  name="orderUrl"
                  className="documentInput"
                  onChange={onDocumentUpload}
                />
                {props.form.orderUrl && (
                  <a href={props.form.orderUrl} className="document-link">
                    {props.form.orderUrlName}
                    <i className="delete-icon doc-icon" id='orderUrl' onClick={deleteHandler}></i>
                  </a>
                )}
                <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                  (pdf одним файлом, до 10 МБайт)
                </span>
              </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>);
  }
  
  else if (props.departmentHeadCom) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправлення заявки до комісії</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[17].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.conf) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Налаштування</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Налаштування номеру посвідчення</span>
              <input
                autoFocus={true}
                type="text"
                name="value"
                onChange={changeHandler}
                value={props.form.value}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.invoRetFed) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Повернення заявки до федерації</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина повернення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.invoHeadRet) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Повернення заявки до спеціаліста</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина повернення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.incoCom) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправка на комісію</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[17].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.instruction) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Додавання інструкції</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Назва</span>
              <input
                autoFocus={true}
                type="text"
                name="title"
                onChange={changeHandler}
                value={props.form.title}
              />
            </label>
            <label className="labelText">
              <span>Подробиці</span>
              <input
                type="text"
                name="description"
                onChange={changeHandler}
                value={props.form.description}
              />
            </label>
            <label
              className="labelText"
              title=" 
                                                Всі організації = any;
                                                Всі зареєстровані користувачі = 00;
                                                Суб`єкт подання = 0;
                                                РегіонСпорт = 1;
                                                Концелярія = 2;
                                                Олімпійський департамент = 3;
                                                Неолімпійський департамент = 4;
                                                ІнваСпорт = 5;
                                                Відділи департаментів = 6
                                                Тренери = 7; 
                                                Куратори = 8;
                                                Федерації = 9; 
                                                НагородСпорт = 10;
                                                "
            >
              <span>Тип організації</span>
              <input type="text" name="type" onChange={changeHandler} value={props.form.type} />
            </label>

            {/* Субьект подання = 0;
РегіонСпорт = 1; (3)(7)
Концелярія = 2;
Олімпійський департамент = 3;
Неолімпійський департамент = 4;
ІнваСпорт = 5;
Відділи департаментів = 6
Тренери = 7; (4)
Куратори = 8; (4)
Федерації = 9; 
НагородСпорт = 10;
 */}
            <label className="labelText">
              {!props.form.url && (
                <label className="icon-text-btn-secondary dow" htmlFor="url">
                  Завантажити інструкцію
                </label>
              )}
              <input
                accept="doc,.docx,.xml,application/msword,application/vnd.openxmlprops.formats-officedocument.wordprocessingml.document,text/plain, application/pdf"
                type="file"
                id="url"
                name="url"
                className="documentInput"
                onChange={onInstructionUpload}
              />
              {props.form.url && (
                <a
                  href={props.form.url}
                  id="url"
                  className="document-link"
                  style={{ paddingRight: '30px' }}
                >
                  {props.form.scanName} {!props.form.scanName && props.form.title}
                  <i
                    className="delete-icon doc-icon"
                    style={{ right: 0 }}
                    id="url"
                    onClick={deleteInstructionHandler}
                  ></i>
                </a>
              )}
            </label>
            <label className="labelText">
              {!props.form.videoUrl && (
                <label className="icon-text-btn-secondary dow" htmlFor="videoUrl">
                  Завантажити відео
                </label>
              )}
              <input
                accept=".mp4,.mp3"
                type="file"
                id="videoUrl"
                name="videoUrl"
                className="documentInput"
                onChange={onInstructionUpload}
              />
              {props.form.videoUrl && (
                <a
                  href={props.form.videoUrl}
                  id="videoUrl"
                  className="document-link"
                  style={{ paddingRight: '30px' }}
                >
                  {props.form.videoName} {!props.form.videoName && props.form.title}
                  <i
                    className="delete-icon doc-icon"
                    id="videoUrl"
                    style={{ right: 0 }}
                    onClick={deleteInstructionHandler}
                  ></i>
                </a>
              )}
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.reviewActive) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Налаштування звіту</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Від</span>
              <input
                type="date"
                max={new Date().toISOString().split('T')[0]}
                name="assignDateFrom"
                onChange={changeHandler}
                value={props.form.assignDateFrom}
              />
            </label>
            <label className="labelText">
              <span>До</span>
              <input
                type="date"
                max={new Date().toISOString().split('T')[0]}
                name="assignDateTo"
                onChange={changeHandler}
                value={props.form.assignDateTo}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.organisationHeadRet) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправлення заявки до керівника відділу</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.organisationHeadDec) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відмова в присвоєнні звання</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відмови</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[16].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відмови</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.organisationHeadCom) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Відправлення заявки до комісії</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Причина відправлення</span>
              <select name="desc" autoFocus={true} onChange={changeSelectSel}>
                <option value=""></option>
                {fullList &&
                  fullList.length > 0 &&
                  fullList[17].map((elem) => {
                    return (
                      <option
                        selected={
                          props.form &&
                          props.form.descid ===
                            elem.id
                        }
                        value={
                          elem.description
                        }
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            

            <label className="labelText">
              <span>Причина відправлення</span>
              <textarea
                autoFocus={true}
                type="text"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                name="comment"
                onChange={changeHandlerHan}
                value={parcialForm.comment || ""}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } 
  else if (props.certificateNumberEdit) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Редагування номеру сертифікату</h1>
          <form onSubmit={formSubmit}>
          <label className="labelText">
              <span>Номер сертифікату</span>
              <input
                type="text"
                name="number"
                onChange={changeHandler}
                value={props.form.number}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.sport && props.noolimp) {
    if (sports.length === 0 && props.form.sports && props.form.sports.length !== 0) {
      let arr = [];
      props.form.sports.forEach((element) => {
        arr.push(element.id);
      });
      setSports(arr);
    }

    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Вибір спорту </h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <select
                name="sports[]"
                autoFocus={true}
                multiple
                style={{ height: '290px' }}
                onChange={changeSelectMultiple}
              >
                <option value=""></option>
                {fullList &&
                  fullList[1] &&
                  fullList[1][1] &&
                  fullList[1][1].sports.map((elem) => {
                    if (sports.includes(elem.id)) {
                      return (
                        <option
                          selected
                          style={{ background: '#004BC1', color: 'white' }}
                          value={elem.id}
                          id={elem.id}
                          key={elem.id}
                        >
                          {elem.title}
                        </option>
                      );
                    }
                  })}
                {fullList &&
                  fullList[1] &&
                  fullList[1][1] &&
                  fullList[1][1].sports.map((elem) => {
                    if (!sports.includes(elem.id)) {
                      return (
                        <option value={elem.id} id={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    }
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } 
  else if (props.sport && props.olimp && props.currentOrganisationSports?.length>0) {
    if (sports.length === 0 && props.form.sports && props.form.sports.length !== 0) {
      let arr = [];
      props.form.sports.forEach((element) => {
        arr.push(element.id);
      });
      setSports(arr);
    }
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Вибір спорту </h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <select
                name="sports[]"
                autoFocus={true}
                multiple
                style={{ height: '290px' }}
                onChange={changeSelectMultiple}
              >
                <option value=""></option>
                {props.currentOrganisationSports.map((elem) => {
                    if (sports.includes(elem.id)) {
                      return (
                        <option
                          selected
                          style={{ background: '#004BC1', color: 'white' }}
                          value={elem.id}
                          id={elem.id}
                          key={elem.id}
                        >
                          {elem.title}
                        </option>
                      );
                    }
                  })}
                {props.currentOrganisationSports.map((elem) => {
                    if (!sports.includes(elem.id)) {
                      return (
                        <option value={elem.id} id={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    }
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  }
  else if (props.sport && props.olimp) {
    if (sports.length === 0 && props.form.sports && props.form.sports.length !== 0) {
      let arr = [];
      props.form.sports.forEach((element) => {
        arr.push(element.id);
      });
      setSports(arr);
    }

    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Вибір спорту </h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <select
                name="sports[]"
                autoFocus={true}
                multiple
                style={{ height: '290px' }}
                onChange={changeSelectMultiple}
              >
                <option value=""></option>
                {fullList &&
                  fullList[1] &&
                  fullList[1][0] &&
                  fullList[1][0].sports.map((elem) => {
                    if (sports.includes(elem.id)) {
                      return (
                        <option
                          selected
                          style={{ background: '#004BC1', color: 'white' }}
                          value={elem.id}
                          id={elem.id}
                          key={elem.id}
                        >
                          {elem.title}
                        </option>
                      );
                    }
                  })}
                {fullList &&
                  fullList[1] &&
                  fullList[1][0] &&
                  fullList[1][0].sports.map((elem) => {
                    if (!sports.includes(elem.id)) {
                      return (
                        <option value={elem.id} id={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    }
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.department) {
    if (!ready) {
      getDepartments();
    }
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Оберіть відділ</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Відділ департаменту</span>
              <select name="departmentId" autoFocus={true} onChange={changeSelect}>
                <option value=""></option>
                {departments &&
                  departments &&
                  departments.map((elem) => {
                    return (
                      <option
                        selected={props.comment && props.comment.departmentId === elem.id}
                        value={elem.id}
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.name}
                      </option>
                    );
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Обрати
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.trainer) {
    if (!ready) {
      getTrainers();
    }
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Оберіть тренера</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Тренер</span>
              <select name="trainerId" autoFocus={true} onChange={changeSelect}>
                <option value=""></option>
                {mates &&
                  mates.length > 0 &&
                  mates.map((elem) => {
                    return (
                      <option
                        selected={props.comment && props.comment.trainerId === elem.id}
                        value={elem.id}
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.lastName} {elem.firstName} {elem.secondName} <br />
                        {elem.sports &&
                          elem.sports.map((el) => {
                            return ` ${el.title} `;
                          })}
                      </option>
                    );
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Обрати
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.office) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Реєстрація заявки</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Реєстраційний №</span>
              <input
                className=""
                autoFocus={true}
                type="text"
                name="comment"
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.filter) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1 style={{position:'relative'}}>Фільтр
          <div className='closeIconButton' onClick={closeClick}>x</div>
          </h1>
          <form onSubmit={formSubmit}>
            <button onClick={resetFilter} className="filter-button">
              <i className="icon delete-icon"></i> Скинути фільтр
            </button>
            {(!auth.isLoggedIn ||
              (currentOrganisation &&
                currentOrganisation.type !== 0 &&
                currentOrganisation.type !== 1)) && (
              <label className="labelText">
                <span>Регіон</span>
                <select
                  name="regionId"
                  autoFocus={true}
                  accessKey="0"
                  onChange={changeSelectFilter}
                  value={(props.form[0] && props.form[0].value) || ''}
                >
                  <option></option>
                  {fullList &&
                    fullList[0] &&
                    fullList[0].map((elem) => {
                      return (
                        <option value={elem.id} id={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    })}
                </select>
              </label>
            )}
            <label className="labelText">
              <span>Спортивне звання</span>
              <select
                name="athleteCategoryTitle"
                autoFocus={true}
                accessKey="1"
                onChange={changeSelectFilter}
                value={(props.form[1] && props.form[1].value) || ''}
              >
                <option></option>
                {fullList &&
                  fullList[2] &&
                  fullList[2].map((elem) => {
                    return (
                      <option
                        value={elem.title + '/' + elem.description}
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>

            <label className="labelText" htmlFor="">
              <span>Спортивна категорія</span>
              <select
                name="sportCategoryId"
                accessKey="2"
                onChange={changeSelectFilter}
                value={(props.form[2] && props.form[2].value) || ''}
              >
                <option value=""></option>
                {fullList &&
                  fullList[1] &&
                  fullList[1].map((elem, index) => {
                    return (
                      <option value={elem.id} id={elem.id} key={elem.id} accessKey={index}>
                        {elem.title}
                      </option>
                    );
                  })}
                <option value={2} id={2} key={2} accessKey={'99'}>
                  Види спорту осіб з інвалідністю (усі нозології)
                </option>
              </select>
            </label>

            <label className="labelText" htmlFor="">
              <span>Вид спорту</span>
              <select
                name="sportTitle"
                accessKey="3"
                onChange={changeSelectFilter}
                value={(props.form[3] && props.form[3].value) || ''}
                disabled={!(props.form[2] && props.form[2].value)}
              >
                {!(props.form[2] && props.form[2].value) && (
                  <option value="">- спочатку оберіть спортивну категорію -</option>
                )}
                {props.form[2] && props.form[2].value && <option value=""></option>}
                {fullList &&
                  fullList[1] &&
                  props.form[2] &&
                  props.form[2].value &&
                  fullList[1].filter((it) => it.id === props.form[2].value).length &&
                  fullList[1]
                    .filter((it) => it.id === props.form[2].value)[0]
                    .sports.map((elem) => {
                      return (
                        <option
                          value={elem.title + ''}
                          id={elem.id + ' ' + elem.title}
                          key={elem.id + ' ' + elem.title}
                        >
                          {elem.title}
                        </option>
                      );
                    })}
              </select>
            </label>

            {props.filterCert && (
              <>
                <label className="labelText">
                  <span>Від</span>
                  <input
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    name="assignDateFrom"
                    accessKey="4"
                    onChange={changeHandler}
                    value={(props.form[4] && props.form[4].value) || ''}
                  />
                </label>
                <label className="labelText">
                  <span>До</span>
                  <input
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    name="assignDateTo"
                    accessKey="5"
                    onChange={changeHandler}
                    value={(props.form[5] && props.form[5].value) || ''}
                  />
                </label>
              </>
            )}
            {props.filterApp && (
              <>
                <label className="labelText">
                  <span>Від</span>
                  <input
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    name="updatedAtFrom"
                    accessKey="4"
                    onChange={changeHandler}
                    value={(props.form[4] && props.form[4].value) || ''}
                  />
                </label>
                <label className="labelText">
                  <span>До</span>
                  <input
                    type="date"
                    max={new Date().toISOString().split('T')[0]}
                    name="updatedAtTo"
                    accessKey="5"
                    onChange={changeHandler}
                    value={(props.form[5] && props.form[5].value) || ''}
                  />
                </label>
              </>
            )}

            <label className="labelText" htmlFor="">
              <span>Стать спортсмена</span>
              <select
                name="sex"
                accessKey="6"
                onChange={changeSelectFilter}
                value={(props.form[6] && props.form[6].value) || ''}
              >
                {<option value=""></option>}
                {fullList &&
                fullList[9].map((elem) => {
                      return (
                        <option
                          value={elem.id }
                          id={elem.id + ' ' + elem.title}
                          key={elem.id + ' ' + elem.title}
                        >
                          {elem.title}
                        </option>
                      );
                    })}
              </select>
            </label>
            <label className="labelText" htmlFor="">
              <span>Стать тренера</span>
              <select
                name="trainerSex"
                accessKey="7"
                onChange={changeSelectFilter}
                value={(props.form[7] && props.form[7].value) || ''}
              >
                {<option value=""></option>}
                {fullList &&
                fullList[9].map((elem) => {
                      return (
                        <option
                          value={elem.id }
                          id={elem.id + ' ' + elem.title}
                          key={elem.id + ' ' + elem.title}
                        >
                          {elem.title}
                        </option>
                      );
                    })}
              </select>
            </label>
            <label className="form-switch labelText">
              <input type="checkbox" name='isFirstTrainer' accessKey='8' checked={props.form[8]?.value=='1'} onChange={changeSelectFilterCheck} />
              <i></i> Перший тренер
            </label>

            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.trainerApply || props.trainerHeadApply) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Результат опрацювання</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Коментар до результату</span>
              <textarea
                className=""
                autoFocus={true}
                type="number"
                name="comment"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" disabled={!props.form?.comment || props.form?.comment?.length<1} type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } 
  
  else if (props.invoDepFederation) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Узгодження заявки</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Коментар до узгодження</span>
              <textarea
                className=""
                autoFocus={true}
                type="number"
                name="comment"
                rows="20"
                cols="100"
                style={{ resize: 'true' }}
                onChange={changeHandler}
                value={props.form.comment}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Відправити
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } 
  
  
  else if (props.element === 'event') {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Редагувати елемент</h1>
          <form onSubmit={achievmentsSubmit}>
            <label className="labelText">
              <span>Дата початку</span>
              <input
                className=""
                autoFocus={true}
                type="date"
                name="eventDateStart"
                max={new Date().toISOString().split('T')[0]}
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].eventDateStart
                    ? parcialForm.achievements[parcialForm.index].eventDateStart
                    : ''
                }
              />
            </label>
            <label className="labelText">
              <span>Дата закінчення</span>
              <input
                className=""
                type="date"
                name="eventDateEnd"
                onChange={changeHandlerAchievements}
                max={new Date().toISOString().split('T')[0]}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].eventDateEnd
                    ? parcialForm.achievements[parcialForm.index].eventDateEnd
                    : ''
                }
              />
            </label>
            {<label className="labelText">
              <span> Назва змагань</span>
              <input
                className=""
                type="text"
                name="eventTitle"
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].eventTitle
                    ? parcialForm.achievements[parcialForm.index].eventTitle
                    : ''
                }
              />
            </label>}

            <label className="labelText">
              <span>Категорія змагань</span>
              <select name="eventTitleId" onChange={changeSelectAchievements}>
                <option></option>
                {fullList &&
                  fullList[15] &&
                  fullList[15].map((elem) => {
                    return(
                      <option
                          selected={
                            parcialForm.achievements &&
                            parcialForm.achievements[parcialForm.index] &&
                            parcialForm.achievements[parcialForm.index]?.eventTitleId === elem.id
                          }
                          value={elem.title}
                          id={elem.id}
                          key={elem.id}
                        >
                          {elem.title}
                        </option>
                    )
                  })}
                        <option  
                          value={'інше'}
                          id={'інше'}
                        >інше</option>
              </select>
            </label>

            {parcialForm.achievements[parcialForm.index]?.eventTitleId=='інше' &&
              <label className="labelText">
                <span> Категорія змагань</span>
                <input
                  className=""
                  type="text"
                  name="eventTitleIdTitle"
                  onChange={changeHandlerAchievements}
                  value={
                    parcialForm.achievements &&
                    parcialForm.achievements[parcialForm.index] &&
                    parcialForm.achievements[parcialForm.index]?.eventTitleIdTitle
                      ? parcialForm.achievements[parcialForm.index]?.eventTitleIdTitle
                      : ''
                  }
                />
              </label>}
            {parcialForm.athleteCategoryTitleId=='cb4b06a9-d396-4791-b646-4659a068a5df' && <label className="labelText">
              <span> ПІБ спортсмена</span>
              <input
                className=""
                type="text"
                name="eventAthlet"
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].eventAthlet
                    ? parcialForm.achievements[parcialForm.index].eventAthlet
                    : ''
                }
              />
            </label>}
            {/* <label className="labelText">
              <span>Ранг, категорія змагань</span>
              <select name="eventCategory" onChange={changeSelectAchievements}>
                <option></option>
                {fullList &&
                  fullList[6] &&
                  fullList[6].map((elem) => {
                    return (
                      <option
                        selected={
                          parcialForm.achievements &&
                          parcialForm.achievements[parcialForm.index] &&
                          parcialForm.achievements[parcialForm.index].eventCategory === elem.id
                        }
                        value={elem.title}
                        id={elem.id}
                        key={elem.id}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label> */}
            <label className="labelText">
              <span>Дисципліна</span>
              <select name="eventDescipline" onChange={changeSelectAchievements}>
                <option></option>
                {fullList &&
                  fullList[1] &&
                  fullList[1].map((elem) => {
                    return(
                    elem.id==props.form.sportCategoryId && 
                      elem.sports.map(el=>{
                        return(
                        el.id==props.form.sportId&& 
                          el.sportDiscipline.map(sport=>{
                            return (
                              <option
                                selected={
                                  parcialForm.achievements &&
                                  parcialForm.achievements[parcialForm.index] &&
                                  parcialForm.achievements[parcialForm.index].eventDescipline === sport.id
                                }
                                value={sport.title}
                                id={sport.id}
                                key={sport.id}
                              >
                                {sport.title}
                              </option>
                            );
                          })
                        )
                      })
                    )
                  })}
              </select>
            </label>
            <label className="labelText">
              <span> Уточнення до дисципліни</span>
              <input
                className=""
                type="text"
                name="eventResultAdded"
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].eventResultAdded
                    ? parcialForm.achievements[parcialForm.index].eventResultAdded
                    : ''
                }
              />
            </label>
            
            <label className="labelText">
              <span>Виконаний результат</span>
              <select name="eventResultId" onChange={changeSelectAchievements}>
                <option></option>
                {fullList &&
                  fullList[14] &&
                  fullList[14].map((elem) => {
                    return(
                      <option
                          selected={
                            parcialForm.achievements &&
                            parcialForm.achievements[parcialForm.index] &&
                            parcialForm.achievements[parcialForm.index]?.eventResultId === elem.id
                          }
                          value={elem.title}
                          id={elem.id}
                          key={elem.id}
                        >
                          {elem.title}
                        </option>
                    )
                  })}
                        <option  
                          value={''}
                          id={'інше'}
                        >інше</option>
              </select>
            </label>

            {parcialForm.achievements[parcialForm.index]?.eventResultId=='інше' &&
              <label className="labelText">
                <span>Виконаний результат</span>
                <input
                  className=""
                  type="text"
                  name="eventResultIdTitle"
                  onChange={changeHandlerAchievements}
                  value={
                    parcialForm.achievements &&
                    parcialForm.achievements[parcialForm.index] &&
                    parcialForm.achievements[parcialForm.index]?.eventResultIdTitle
                      ? parcialForm.achievements[parcialForm.index]?.eventResultIdTitle
                      : ''
                  }
                />
              </label>}

            {/* <label className="labelText">
              <span> ПІБ головного судді</span>
              <input
                className=""
                type="text"
                name="mainJudge"
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].mainJudge
                    ? parcialForm.achievements[parcialForm.index].mainJudge
                    : ''
                }
              />
            </label>
            <label className="labelText">
                  <span>Країна, місто головного судді</span>
                  <input
                    className=""
                    type="text"
                    name="eventMainJudgeCity"
                    onChange={changeHandlerAchievements}
                    value={parcialForm.achievements &&
                      parcialForm.achievements[parcialForm.index] &&
                      parcialForm.achievements[parcialForm.index].eventMainJudgeCity
                        ? parcialForm.achievements[parcialForm.index].eventMainJudgeCity
                        : ''}
                  />
                </label>
                <label className="labelText">
                  <span>Cуддівська категорія головного судді</span>
                  <select name="eventMainJudgeCategory" onChange={changeSelectAchievements}>
                    <option></option>
                    {fullList &&
                      fullList[5] &&
                      fullList[5].map((elem) => {
                        return (
                          <option
                            selected={parcialForm.achievements &&
                              parcialForm.achievements[parcialForm.index] &&
                              parcialForm.achievements[parcialForm.index].eventMainJudgeCategory
                                ? parcialForm.achievements[parcialForm.index].eventMainJudgeCategory === elem.id
                                : ''}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>

            <label className="labelText">
              <span> ПІБ заступника головного судді</span>
              <input
                className=""
                type="text"
                name="mainSubJudge"
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].mainSubJudge
                    ? parcialForm.achievements[parcialForm.index].mainSubJudge
                    : ''
                }
              />
            </label>
            <label className="labelText">
                  <span>Країна, місто заступника головного судді</span>
                  <input
                    className=""
                    type="text"
                    name="eventMainSubJudgeCity"
                    onChange={changeHandlerAchievements}
                    value={parcialForm.achievements &&
                      parcialForm.achievements[parcialForm.index] &&
                      parcialForm.achievements[parcialForm.index].eventMainSubJudgeCity
                        ? parcialForm.achievements[parcialForm.index].eventMainSubJudgeCity
                        : ''}
                  />
                </label>
                <label className="labelText">
                  <span>Cуддівська категорія заступника головного судді</span>
                  <select name="eventMainSubJudgeCategory" onChange={changeSelectAchievements}>
                    <option></option>
                    {fullList &&
                      fullList[5] &&
                      fullList[5].map((elem) => {
                        return (
                          <option
                            selected={parcialForm.achievements &&
                              parcialForm.achievements[parcialForm.index] &&
                              parcialForm.achievements[parcialForm.index].eventMainSubJudgeCategory
                                ? parcialForm.achievements[parcialForm.index].eventMainSubJudgeCategory === elem.id
                                : ''}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>

                <label className="labelText">
              <span> ПІБ судді (секретаря)</span>
              <input
                className=""
                type="text"
                name="judge"
                onChange={changeHandlerAchievements}
                value={
                  parcialForm.achievements &&
                  parcialForm.achievements[parcialForm.index] &&
                  parcialForm.achievements[parcialForm.index].judge
                    ? parcialForm.achievements[parcialForm.index].judge
                    : ''
                }
              />
            </label>
            <label className="labelText">
                  <span>Країна, місто судді (секретаря)</span>
                  <input
                    className=""
                    type="text"
                    name="judgeCity"
                    onChange={changeHandlerAchievements}
                    value={parcialForm.achievements &&
                      parcialForm.achievements[parcialForm.index] &&
                      parcialForm.achievements[parcialForm.index].judgeCity
                        ? parcialForm.achievements[parcialForm.index].judgeCity
                        : ''}
                  />
                </label>
                <label className="labelText">
                  <span>Cуддівська категорія судді (секретаря)</span>
                  <select name="judgeCategory" onChange={changeSelectAchievements}>
                    <option></option>
                    {fullList &&
                      fullList[5] &&
                      fullList[5].map((elem) => {
                        return (
                          <option
                            selected={parcialForm.achievements &&
                              parcialForm.achievements[parcialForm.index] &&
                              parcialForm.achievements[parcialForm.index].judgeCategory
                                ? parcialForm.achievements[parcialForm.index].judgeCategory === elem.id
                                : ''}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label> */}


            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.judgeEdit) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Редагувати елемент</h1>
          <form onSubmit={formSubmit}>
            {props.element === 'eventJudge' && (
              <>
                {' '}
                <label className="labelText">
                  <span>Прізвище</span>
                  <input
                    className=""
                    autoFocus={true}
                    type="text"
                    name="eventJudgeLastName"
                    onChange={changeHandler}
                    value={props.form.eventJudgeLastName}
                  />
                </label>
                <label className="labelText">
                  <span>Ім'я</span>
                  <input
                    className=""
                    type="text"
                    name="eventJudgeFirstName"
                    onChange={changeHandler}
                    value={props.form.eventJudgeFirstName}
                  />
                </label>
                <label className="labelText">
                  <span>По батькові</span>
                  <input
                    className=""
                    type="text"
                    name="eventJudgeSecondName"
                    onChange={changeHandler}
                    value={props.form.eventJudgeSecondName}
                  />
                </label>
                <label className="labelText">
                  <span>Країна, місто</span>
                  <input
                    className=""
                    type="text"
                    name="eventJudgeCity"
                    onChange={changeHandler}
                    value={props.form.eventJudgeCity}
                  />
                </label>
                <label className="labelText">
                  <span>Cуддівська категорія</span>
                  <select name="eventJudgeCategory" onChange={changeSelect}>
                    <option></option>
                    {fullList &&
                      fullList[5] &&
                      fullList[5].map((elem) => {
                        return (
                          <option
                            selected={props.form.eventJudgeCategory === elem.id}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </>
            )}
            {props.element === 'eventViceMainJudge' && (
              <>
                {' '}
                <label className="labelText">
                  <span>Прізвище</span>
                  <input
                    className=""
                    autoFocus={true}
                    type="text"
                    name="eventViceMainJudgeLastName"
                    onChange={changeHandler}
                    value={props.form.eventViceMainJudgeLastName}
                  />
                </label>
                <label className="labelText">
                  <span>Ім'я</span>
                  <input
                    className=""
                    type="text"
                    name="eventViceMainJudgeFirstName"
                    onChange={changeHandler}
                    value={props.form.eventViceMainJudgeFirstName}
                  />
                </label>
                <label className="labelText">
                  <span>По батькові</span>
                  <input
                    className=""
                    type="text"
                    name="eventViceMainJudgeSecondName"
                    onChange={changeHandler}
                    value={props.form.eventViceMainJudgeSecondName}
                  />
                </label>
                <label className="labelText">
                  <span>Країна, місто</span>
                  <input
                    className=""
                    type="text"
                    name="eventViceMainJudgeCity"
                    onChange={changeHandler}
                    value={props.form.eventViceMainJudgeCity}
                  />
                </label>
                <label className="labelText">
                  <span>Cуддівська категорія</span>
                  <select name="eventViceMainJudgeCategory" onChange={changeSelect}>
                    <option></option>
                    {fullList &&
                      fullList[5] &&
                      fullList[5].map((elem) => {
                        return (
                          <option
                            selected={props.form.eventViceMainJudgeCategory === elem.id}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </>
            )}
            {props.element === 'eventMainJudge' && (
              <>
                {' '}
                <label className="labelText">
                  <span>Прізвище</span>
                  <input
                    className=""
                    type="text"
                    autoFocus={true}
                    name="eventMainJudgeLastName"
                    onChange={changeHandler}
                    value={props.form.eventMainJudgeLastName}
                  />
                </label>
                <label className="labelText">
                  <span>Ім'я</span>
                  <input
                    className=""
                    type="text"
                    name="eventMainJudgeFirstName"
                    onChange={changeHandler}
                    value={props.form.eventMainJudgeFirstName}
                  />
                </label>
                <label className="labelText">
                  <span>По батькові</span>
                  <input
                    className=""
                    type="text"
                    name="eventMainJudgeSecondName"
                    onChange={changeHandler}
                    value={props.form.eventMainJudgeSecondName}
                  />
                </label>
                <label className="labelText">
                  <span>Країна, місто</span>
                  <input
                    className=""
                    type="text"
                    name="eventMainJudgeCity"
                    onChange={changeHandler}
                    value={props.form.eventMainJudgeCity}
                  />
                </label>
                <label className="labelText">
                  <span>Cуддівська категорія</span>
                  <select name="eventMainJudgeCategory" onChange={changeSelect}>
                    <option></option>
                    {fullList &&
                      fullList[5] &&
                      fullList[5].map((elem) => {
                        return (
                          <option
                            selected={props.form.eventMainJudgeCategory === elem.id}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>
              </>
            )}

            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.form && props.form.category === 'ministry-executives') {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          {props.edit ? <h1>Редагувати елемент</h1> : <h1>Новий елемент</h1>}
          <label className="form-subheader-label">Довідник</label>
          {props.title ? (
            <div className="form-subheader">{props.title}</div>
          ) : (
            <div>{props.form.category}</div>
          )}

          <form onSubmit={formSubmit}>
            {/* <label className='labelText'> 
                        <span>Тип довідника</span>
                        <input className='' type="text"  name='type'  onChange={changeHandler} value={props.form.type}/>
                    </label> */}
            <label className="labelText">
              <span>Прізвище</span>
              <input
                autoFocus={true}
                className=""
                type="text"
                name="lastName"
                onChange={changeHandler}
                value={props.form.lastName}
              />
            </label>
            <label className="labelText">
              <span>Ім'я</span>
              <input
                className=""
                type="text"
                name="firstName"
                onChange={changeHandler}
                value={props.form.firstName}
              />
            </label>
            <label className="labelText">
              <span>По батькові</span>
              <input
                className=""
                type="text"
                name="secondName"
                onChange={changeHandler}
                value={props.form.secondName}
              />
            </label>
            <label className="labelText">
              <span>Посада</span>
              <input
                className=""
                type="text"
                name="position"
                onChange={changeHandler}
                value={props.form.position}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.form && props.form.category === 'users') {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Редагувати дані користувача</h1>
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Прізвище</span>
              <input
                autoFocus={true}
                className=""
                type="text"
                name="lastName"
                onChange={changeHandler}
                value={props.form.lastName}
              />
            </label>
            <label className="labelText">
              <span>Ім'я</span>
              <input
                className=""
                type="text"
                name="firstName"
                onChange={changeHandler}
                value={props.form.firstName}
              />
            </label>
            <label className="labelText">
              <span>По батькові</span>
              <input
                className=""
                type="text"
                name="secondName"
                onChange={changeHandler}
                value={props.form.secondName}
              />
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (
    (props.form &&
      props.form.category === 'sport-categories/1a5066b7-3dea-4fa1-8c16-dd5a449cb317/sports') ||
    (props.form &&
      props.form.category === 'sport-categories/1a5066b7-3dea-4fa1-8c16-dd5a449cb317/sports/')
  ) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          {props.edit ? <h1>Редагувати елемент</h1> : <h1>Новий елемент</h1>}
          <label className="form-subheader-label">Довідник</label>
          {props.title ? (
            <div className="form-subheader">{props.title}</div>
          ) : (
            <div>{props.form.category}</div>
          )}

          <form onSubmit={formSubmit}>
            <label className="labelText" htmlFor="">
              <span>Cпортивна категорія</span>
              <select autoFocus={true} name="sportCategory" onChange={changeSelectFirst}>
                <option value="" selected></option>
                {fullList &&
                  fullList[1] &&
                  fullList[1].map((elem, index) => {
                    return (
                      <option value={elem.title} id={elem.id} key={elem.id} accessKey={index}>
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            <label className="labelText" htmlFor="">
              <span>Вид спорту</span>
              <select name="name" onChange={changeSelect}>
                <option value=""></option>
                {ind &&
                  fullList &&
                  fullList[1] &&
                  fullList[1][+ind] &&
                  fullList[1][+ind].sports.map((elem) => {
                    return (
                      <option
                        value={elem.title}
                        id={elem.id + ' ' + elem.title}
                        key={elem.id + ' ' + elem.title}
                      >
                        {elem.title}
                      </option>
                    );
                  })}
              </select>
            </label>
            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.edit && !props.editOrg) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Редагування назви елементу довідника</h1>
          {props.title && <label className="form-subheader-label">Довідник</label>}
          {props.title ? (
            <div className="form-subheader">{props.title}</div>
          ) : (
            <div>{props.form.category}</div>
          )}
          <label className="form-subheader-label">Поточна назва</label>
          <div className="form-subheader">{props.form.prevName}</div>
          {(props.form.category === 'sport-titles' ||
          props.form.category === 'sport-categories' ||
            props.form.category === 'tournament-ranks') && (
            <label className="form-subheader-label">Поточна коротка назва</label>
          )}
          {
          props.form.category ==='applications-rejected'||
          props.form.category ==='commission-applications'
          &&
           <label className="form-subheader-label">Поточний текст</label>
          }

          {(props.form.category === 'sport-titles' ||
            props.form.category === 'sport-categories' ||
            props.form.category ==='commission-applications'||
            props.form.category ==='applications-rejected'||
            props.form.category === 'tournament-ranks') && (
            <div className="form-subheader">{props.form.prevShortName}</div>
          )}
          {(props.form.category || '').indexOf('/sports') !== -1 &&(props.form.category || '').indexOf('/discipline') == -1 && (
            <label className="form-subheader-label">Поточна назва у родовому відмінку</label>
          )}
          {(props.form.category || '').indexOf('/sports') !== -1 &&(props.form.category || '').indexOf('/discipline') == -1 && (
            <div className="form-subheader">{props.form.prevTitleGenitive}</div>
          )}
          {(props.form.category || '').indexOf('/sports') !== -1 &&(props.form.category || '').indexOf('/discipline') == -1 && (
            <label className="form-subheader-label">Поточна назва у давальному відмінку</label>
          )}
          {(props.form.category || '').indexOf('/sports') !== -1 &&(props.form.category || '').indexOf('/discipline') == -1 && (
            <div className="form-subheader">{props.form.prevTitleDative}</div>
          )}

          <form onSubmit={formSubmit}>
            {  props.form.category !=='users-list-permissions'  &&<label className="labelText">
              <span>Назва елементу довідника</span>
              <input
                autoFocus={true}
                type="text"
                name="name"
                onChange={changeHandler}
                value={props.form.name}
              />
            </label>}
            {(props.form.category === 'applications-rejected' ||
              props.form.category === 'commission-applications') && (
              <label className="labelText">
                <span>Текст</span>
                <textarea
                autoFocus={true}
                rows="20"
                cols="80"
                style={{ resize: 'true', fontSize: '14px' }}
                name="description"
                onChange={changeHandler}
                value={props.form.description}
              />
              </label>
            )}

            {props.form.category ==='users-list-permissions' &&  <label className="labelText">
              {!props.form.url && (
                <label className="icon-text-btn-secondary dow" htmlFor="url">
                  Завантажити копію наказу
                </label>
              )}
              <input
                accept="application/pdf"
                type="file"
                id="url"
                name="url"
                className="documentInput"
                onChange={onDocumentUpload}
              />
              {props.form.url && (
                <a href={props.form.url} className="document-link">
                  {props.form.url}
                  <i className="delete-icon doc-icon" id='urlDecline'onClick={deleteHandler}></i>
                </a>
              )}
              <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                (pdf одним файлом, до 10 МБайт)
              </span>
            </label>}


            {  props.form.category === 'sport-categories' && (
              <label className="labelText">
                <span>Коротка назва</span>
                <input
                  type="text"
                  name="shortName"
                  onChange={changeHandler}
                  value={props.form.shortName}
                />
              </label>
            )}
            
            {props.form.category === 'tournament-ranks'
              && (
              <label className="labelText">
                <span>Коротка назва</span>
                <input
                  type="text"
                  name="description"
                  onChange={changeHandler}
                  value={props.form.shortName}
                />
              </label>
            )}
            {props.form.category === 'sport-titles' && (
              <label className="labelText">
                <span>Коротка назва</span>
                <input
                  type="text"
                  name="description"
                  onChange={changeHandler}
                  value={props.form.shortName}
                />
              </label>
            )}
          {(props.form.category || '').indexOf('/sports') !== -1 &&(props.form.category || '').indexOf('/discipline') == -1 && (
              <label className="labelText">
                <span>Назва у родовому відмінку</span>
                <input
                  type="text"
                  name="titleGenitive"
                  onChange={changeHandler}
                  value={props.form.titleGenitive}
                />
              </label>
            )}
            {(props.form.category || '').indexOf('/sports') !== -1 &&(props.form.category || '').indexOf('/discipline') == -1 && (
              <label className="labelText">
                <span>Назва у давальному відмінку</span>
                <input
                  type="text"
                  name="titleDative"
                  onChange={changeHandler}
                  value={props.form.titleDative}
                />
              </label>
            )}
            {props.form.category === 'sport-titles' && (
              <label className="labelText">
                <span>Номер посвідчення</span>
                <input
                  type="text"
                  name="documentNumber"
                  onChange={changeHandler}
                  value={props.form.documentNumber}
                />
              </label>
            )}
            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.rosterDownload) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Завантаження реєстру {props.subtab?.clone && 'дублікатів посвідчень'} {props.subtab?.refused && 'скасованих звань'}</h1>
          <label className="labelText">Кількість стовпчиків - 12</label>
          <label className="labelText">
            Кількість відступаючих рядків зверху - 2 (тобто рядки з перших 2 рядків не враховуються)
          </label>
          <label className="labelText">
            Перелік стовпчиків по порядку: прізвище, ім’я, по-батькові, вид спорту, адреса(регіон),
            відомство(назва клубу), тренер(ПІБ через пробіл), дата у форматі число/місяць/рік, номер
            наказу, номер посвідчення, за що присвоєно, звання
          </label>
          <label className="labelText">
            {!props.form.scan && (
              <label className="icon-text-btn-secondary dow" htmlFor="scan">
                Обрати документ
              </label>
            )}
            <input
              accept=".csv"
              type="file"
              id="scan"
              name="scan"
              className="documentInput"
              onChange={onRosterUpload}
            />
            {props.form.scan && (
              <a href={props.form.scan} className="document-link">
                {props.form.scanName}
                <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
              </a>
            )}
            <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
              (.csv з роздільником)
            </span>
          </label>
          <label className="labelText">
            {testing && <>Перевіряється, зачекайте...</>}

            {!testing && errorsRoster?.warnings?.length > 0 && (
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                readOnly
                value={errorsRoster?.warnings.map((el) => el)}
              ></textarea>
            )}
            {!testing && errorsRoster?.error?.length > 0 && (
              <textarea
                name=""
                id=""
                cols="30"
                rows="10"
                readOnly
                value={errorsRoster?.error}
              ></textarea>
            )}
          </label>
            {props.subtab.refused &&  <label className="labelText">
              {!props.form.urlDecline && (
                <label className="icon-text-btn-secondary dow" htmlFor="urlDecline">
                  Завантажити копію наказу
                </label>
              )}
              <input
                accept="application/pdf"
                type="file"
                id="urlDecline"
                name="urlDecline"
                className="documentInput"
                onChange={onDocumentUpload}
              />
              {props.form.urlDecline && (
                <a href={props.form.urlDecline} className="document-link">
                  {props.form.urlDeclineName}
                  <i className="delete-icon doc-icon" id='urlDecline'onClick={deleteHandler}></i>
                </a>
              )}
              <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                (pdf одним файлом, до 10 МБайт)
              </span>
            </label>}
          <button
            className="btn-primary text-btn-primary"
            onClick={() => {
              setTesting(true);
              props.saveAddChange();
            }}
            disabled={errorsRoster?.error?.length > 0 || testing}
          >
            Зберегти
          </button>

          <a className="link" onClick={closeClick}>
            Відмінити
          </a>
        </div>
      </div>
    );
  } 
  
  else if (props.declineCer){
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Скасування звання</h1>
           <label className="labelText">
              {!props.form.urlDecline && (
                <label className="icon-text-btn-secondary dow" htmlFor="urlDecline">
                  Завантажити копію наказу
                </label>
              )}
              <input
                accept="application/pdf"
                type="file"
                id="urlDecline"
                name="urlDecline"
                className="documentInput"
                onChange={onDocumentUpload}
              />
              {props.form.urlDecline && (
                <a href={props.form.urlDecline} className="document-link">
                  {props.form.urlDeclineName}
                  <i className="delete-icon doc-icon" id='urlDecline'onClick={deleteHandler}></i>
                </a>
              )}
              <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                (pdf одним файлом, до 10 МБайт)
              </span>
            </label>
          <button
            className="btn-primary text-btn-primary"
            onClick={() => {
              setTesting(true);
              props.saveAddChange();
            }}
            disabled={errorsRoster?.error?.length > 0 || testing}
          >
            Зберегти
          </button>

          <a className="link" onClick={closeClick}>
            Відмінити
          </a>
        </div>
      </div>
    );
  }
  else if (props.add || props.alertCreate) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Нова організація</h1>
          {props.title && <label className="form-subheader-label">Довідник</label>}
          {props.title ? (
            <div className="form-subheader">{props.title}</div>
          ) : (
            <div>{props.form && props.form.category}</div>
          )}
          {/* <label  className='form-subheader-label'>Поточна назва</label>
                    <div className='form-subheader'>{props.form.prevName}</div> */}
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>ЄДРПОУ код організації</span>
              <input
                autoFocus={true}
                className=""
                type="text"
                name="code"
                onChange={changeHandler}
                value={props.form.code}
              />
              {props.error && props.error[0] && props.error[0].param === 'code' && (
                <span className="validError">{props.error[0].msg}</span>
              )}
            </label>
          
            <label className="form-switch labelText">
              <input
                id={'hasNoCode'}
                type="checkbox"
                name="hasNoCode"
                checked={props.form.hasNoCode}
                onChange={setCode}
              />
              <i style={{ marginTop: '4px' }}></i>
              <span style={{ display: 'inline-block', paddingLeft: '10px' }}>
                Не має ЄДРПОУ
              </span>
            </label>

            <label className="labelText">
              <span>Коротка назва</span>
              <input
                className=""
                type="text"
                name="shortName"
                onChange={changeHandler}
                value={props.form.shortName}
              />
            </label>
            <label className="labelText">
              <span>Повна назва</span>
              <input
                className=""
                type="text"
                name="name"
                onChange={changeHandler}
                value={props.form.name}
              />
            </label>
            {/* <label className='labelText'> 
                        <span>Тип</span>
                        <input className='' type="text"  name='type'  onChange={changeHandler} value={props.form.type}/>
                    </label> */}
            {props.alertCreate && (
              <label className="labelText">
                <span>Регіон</span>
                <select name="regionId" onChange={changeSelect}>
                  <option></option>
                  {fullList &&
                    fullList[0] &&
                    fullList[0].map((elem) => {
                      return (
                        <option value={elem.id} id={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    })}
                </select>
              </label>
            )}
            {props.alertCreate && (
              <label className="labelText">
                {!props.form.scan && (
                  <label className="icon-text-btn-secondary dow" htmlFor="scan">
                    Завантажити Статут (Положення) та Витяг з ЄДРПОУ
                  </label>
                )}
                <input
                  accept="application/pdf"
                  type="file"
                  id="scan"
                  name="scan"
                  className="documentInput"
                  onChange={onDocumentUpload}
                />
                {props.form.scan && (
                  <a href={props.form.scan} className="document-link">
                    {props.form.scanName}
                    <i className="delete-icon doc-icon" onClick={deleteHandler}></i>
                  </a>
                )}
                <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                  (pdf одним файлом, до 10 МБайт)
                </span>
              </label>
            )}
            {!props.alertCreate && (
              <button
                className="btn-primary text-btn-primary"
                type="submit"
                disabled={!(props.form.name && props.form.shortName)}
              >
                Зберегти
              </button>
            )}
            {props.alertCreate && (
              <button
                className="btn-primary text-btn-primary"
                type="submit"
                disabled={
                  !(
                    !props.error.length > 0 &&
                    props.form.name &&
                    props.form.shortName &&
                    props.form.scan &&
                    ((props.form.hasNoCode && !props.form.code) || (!props.form.hasNoCode && props.form.code) )
                  )
                }
              >
                Зберегти
              </button>
            )}{' '}
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.editOrg) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Редагування назви елементу </h1>
          {props.title && <label className="form-subheader-label">Довідник</label>}
          {props.title ? (
            <div className="form-subheader">{props.title}</div>
          ) : (
            <div>{props.form.category}</div>
          )}
          {props.form.prevName && (
            <>
              <label className="form-subheader-label">Поточна назва</label>
              <div className="form-subheader">{props.form.prevName}</div>
            </>
          )}
          {props.form.prevShortName && (
            <>
              {' '}
              <label className="form-subheader-label">Поточна коротка назва</label>
              <div className="form-subheader">{props.form.prevShortName}</div>
            </>
          )}
          <form onSubmit={formSubmit}>
            <label className="labelText">
              <span>Повна назва</span>
              <input
                className=""
                type="text"
                name="name"
                onChange={changeHandler}
                value={props.form.name}
              />
            </label>
            <label className="labelText">
              <span>Коротка назва</span>
              <input
                autoFocus={true}
                className=""
                type="text"
                name="shortName"
                onChange={changeHandler}
                value={props.form.shortName}
              />
            </label>
           {props.form.regionId?.length>0 && <label className="labelText">
                <span>Регіон</span>
                <select name="regionId" onChange={changeSelect}>
                  <option></option>
                  {fullList &&
                    fullList[0] &&
                    fullList[0].map((elem) => {
                      return (
                        <option selected={props.form.regionId==elem.id} value={elem.id} id={elem.id} key={elem.id}>
                          {elem.title}
                        </option>
                      );
                    })}
                </select>
              </label>}
            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else if (props.newSupportTicket) {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Запит до підтримки</h1>
          <form onSubmit={formSubmit}>
          <label className="labelText">
                  <span>Тема</span>
                  <select name="topic" onChange={changeSelect}>
                    <option></option>
                    {fullList &&
                      fullList[11] &&
                      fullList[11].map((elem) => {
                        return (
                          <option
                            selected={props.form.topic === elem.id}
                            value={elem.title}
                            id={elem.id}
                            key={elem.id}
                          >
                            {elem.title}
                          </option>
                        );
                      })}
                  </select>
                </label>
            <label className="labelText">
              <span>Питання</span>
              <input
                autoFocus={true}
                className=""
                type="text"
                name="message"
                onChange={changeHandler}
                value={props.form.message}
              />
            </label>
            <button className="btn-primary text-btn-primary" disabled={!(props.form.topic?.length>0 && props.form.message?.length>0)} type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  } else {
    return (
      <div className="form">
        <div className="form-transparent"></div>
        <div className="form-value">
          <h1>Новий елемент довідника</h1>
          <label className="form-subheader-label">Довідник</label>
          {props.title ? (
            <div className="form-subheader">{props.title}</div>
          ) : (
            <div>{props.form && props.form.category}</div>
          )}

          <form onSubmit={formSubmit}>
            {/* <label className='labelText'> 
                        <span>Тип довідника</span>
                        <input className='' type="text"  name='type'  onChange={changeHandler} value={props.form.type}/>
                    </label> */}
             { props.form.category !=='users-list-permissions'  &&<label className="labelText">
              <span>Назва елементу довідника</span>
              <input
                autoFocus={true}
                className=""
                type="text"
                name="name"
                onChange={changeHandler}
                value={props.form && props.form.name}
              />
            </label>}
            {props.form.category ==='users-list-permissions' &&  <label className="labelText">
              {!props.form.url && (
                <label className="icon-text-btn-secondary dow" htmlFor="url">
                  Завантажити копію наказу
                </label>
              )}
              <input
                accept="application/pdf"
                type="file"
                id="url"
                name="url"
                className="documentInput"
                onChange={onDocumentUpload}
              />
              {props.form.url && (
                <a href={props.form.url} className="document-link">
                  {props.form.url}
                  <i className="delete-icon doc-icon" id='urlDecline'onClick={deleteHandler}></i>
                </a>
              )}
              <span style={{ marginTop: '10px', marginLeft: '10px', fontSize: '14px' }}>
                (pdf одним файлом, до 10 МБайт)
              </span>
            </label>}

            {((props.form.category || '').indexOf('/sports') !== -1 && (props.form.category || '').indexOf('/discipline')==1) &&  (
              <label className="labelText">
                <span>Назва у родовому відмінку</span>
                <input
                  className=""
                  type="text"
                  name="titleGenitive"
                  onChange={changeHandler}
                  value={props.form.titleGenitive}
                />
              </label>
            )}
            {((props.form.category || '').indexOf('/sports') !== -1 && (props.form.category || '').indexOf('/discipline')==1) &&  (
              <label className="labelText">
                <span>Назва у давальному відмінку</span>
                <input
                  className=""
                  type="text"
                  name="titleDative"
                  onChange={changeHandler}
                  value={props.form.titleDative}
                />
              </label>
            )}
            {(props.form.category === 'tournament-ranks' ||
              props.form.category === 'sport-titles') && (
              <label className="labelText">
                <span>Коротка назва</span>
                <input
                  className=""
                  type="text"
                  name="description"
                  onChange={changeHandler}
                  value={props.form && props.form.shortName}
                />
              </label>
            )}
            <button className="btn-primary text-btn-primary" type="submit">
              Зберегти
            </button>
            <a className="link" onClick={closeClick}>
              Відмінити
            </a>
          </form>
        </div>
      </div>
    );
  }
};

export default Form;
