import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Avatar from '../../components/Avatar/Avatar';
import { getAllCertificateList, getCertificateRepList } from '../../redux/actions/certificate';

const ReportPage = () => {
  if (document.getElementsByClassName('sidebar')[0]) {
    document.getElementsByClassName('sidebar')[0].hidden = true;
  }
  if (document.getElementsByClassName('sidebar-open')[0]) {
    document.getElementsByClassName('sidebar-open')[0].hidden = true;
  }
  
  const { list } = useSelector((state) => state);
  const history = useHistory();
  const dispatch = useDispatch();
  const [arrRes1, setArr1] = useState([]);
  const [ready, setReady] = useState(false);
  const [arrRes2, setArr2] = useState([]);
  const [arrRes0, setArr0] = useState([]);
  const [arrRes, setArr] = useState([]);
  const [form, setForm] = useState({});
  const backClick = () => {
    history.push('/roster');
  };
  const showRep = () => {
    getCert(form);
  };

  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const getCert = (date) => {
    setReady(false);
    dispatch(getCertificateRepList(date)).then((res) => {
      setArr(res);
      console.log(res);
      setReady(true);
    });
  };

  useEffect(() => {
    showRep();
  }, []);

  return (
    <div className="main application-page">
      <h3 className="public-sub-header">Реєстр присвоєння спортивних звань</h3>
      <div className="auth-menu">
        <Avatar />
      </div>
      <h1 className="public-header" style={{ marginBottom: '30px' }}>
        Звіт по реєстру
      </h1>
      <div className="create-app btn-tab">
        <button className="icon-text-btn-secondary back" onClick={backClick}>
          Назад
        </button>
      </div>
      <div className='reportPageFilter'>
        <label className="labelText">
          <span>Від</span>
          <input
            type="date"
            name="assignDateFrom"
            onChange={changeHandler}
            value={form.assignDateFrom}
          />
        </label>
        <label className="labelText">
          <span>До</span>
          <input
            type="date"
            name="assignDateTo"
            onChange={changeHandler}
            value={form.assignDateTo}
          />
        </label>
        <label className="labelText" style={{ width: '200px' }}>
          <button className="btn-primary text-btn-primary" onClick={showRep}>
            Показати
          </button>
        </label>
      </div>

      {ready && (
        <div style={{ gridArea: 'table', width: '100%' }}>
          <h2>За видами спорту</h2>
          <table style={{ width: '100%', marginTop: '40px',display: 'block',overflowX: 'auto', whiteSpace: 'nowrap'}}>
            <thead>
              <tr>
                <td>Види спорту</td>
                <td>МСУМК</td>
                <td>МСУ</td>
                <td>ЗМСУ</td>
                <td>ЗТУ</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td
                  colSpan="5"
                  style={{ background: '#F5F7FA', fontWeight: 'bold', fontSize: '20px' }}
                >
                  Олімпійські види спорту
                </td>
              </tr>
              {arrRes &&
                arrRes['Олімпійський'].map((el) => {
                    return (
                      <tr>
                        <td>{list[el.sportId]}</td>
                        <td>{el.athleteCategoryTitleId['МСУМК'] ? el.athleteCategoryTitleId['МСУМК'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['МСУ'] ? el.athleteCategoryTitleId['МСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗМСУ'] ? el.athleteCategoryTitleId['ЗМСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗТУ'] ? el.athleteCategoryTitleId['ЗТУ']  : 0}</td>
                      </tr>
                    );
                })}
              <tr>
                <td
                  colSpan="5"
                  style={{ background: '#F5F7FA', fontWeight: 'bold', fontSize: '20px' }}
                >
                  Неолімпійські види спорту
                </td>
              </tr>
              {arrRes &&
                arrRes['Неолімпійський'].map((el) => {
                    return (
                      <tr>
                        <td>{list[el.sportId]}</td>
                        <td>{el.athleteCategoryTitleId['МСУМК'] ? el.athleteCategoryTitleId['МСУМК'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['МСУ'] ? el.athleteCategoryTitleId['МСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗМСУ'] ? el.athleteCategoryTitleId['ЗМСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗТУ'] ? el.athleteCategoryTitleId['ЗТУ']  : 0}</td>
                      </tr>
                    );
                })}
              <tr>
                <td
                  colSpan="5"
                  style={{ background: '#F5F7FA', fontWeight: 'bold', fontSize: '20px' }}
                >
                  Види спорту осіб з інвалідністю з порушеннями зору
                </td>
              </tr>
              {arrRes &&
                arrRes['Види спорту осіб з інвалідністю з порушеннями зору'].map((el) => {
                    return (
                      <tr>
                        <td>{list[el.sportId]}</td>
                        <td>{el.athleteCategoryTitleId['МСУМК'] ? el.athleteCategoryTitleId['МСУМК'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['МСУ'] ? el.athleteCategoryTitleId['МСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗМСУ'] ? el.athleteCategoryTitleId['ЗМСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗТУ'] ? el.athleteCategoryTitleId['ЗТУ']  : 0}</td>
                      </tr>
                    );
                })}


              <tr>
                <td
                  colSpan="5"
                  style={{ background: '#F5F7FA', fontWeight: 'bold', fontSize: '20px' }}
                >
                  Види спорту осіб з інвалідністю з порушеннями розумового і фізичного розвитку

                </td>
              </tr>
              {arrRes &&
                arrRes['Види спорту осіб з інвалідністю з порушеннями розумового і фізичного розвитку'].map((el) => {
                    return (
                      <tr>
                        <td>{list[el.sportId]}</td>
                        <td>{el.athleteCategoryTitleId['МСУМК'] ? el.athleteCategoryTitleId['МСУМК'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['МСУ'] ? el.athleteCategoryTitleId['МСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗМСУ'] ? el.athleteCategoryTitleId['ЗМСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗТУ'] ? el.athleteCategoryTitleId['ЗТУ']  : 0}</td>
                      </tr>
                    );
                })}


              <tr>
                <td
                  colSpan="5"
                  style={{ background: '#F5F7FA', fontWeight: 'bold', fontSize: '20px' }}
                >
                  Види спорту осіб з інвалідністю з порушеннями слуху

                </td>
              </tr>
              {arrRes &&
                arrRes['Види спорту осіб з інвалідністю з порушеннями слуху'].map((el) => {
                    return (
                      <tr>
                        <td>{list[el.sportId]}</td>
                        <td>{el.athleteCategoryTitleId['МСУМК'] ? el.athleteCategoryTitleId['МСУМК'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['МСУ'] ? el.athleteCategoryTitleId['МСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗМСУ'] ? el.athleteCategoryTitleId['ЗМСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗТУ'] ? el.athleteCategoryTitleId['ЗТУ']  : 0}</td>
                      </tr>
                    );
                })}


              <tr>
                <td
                  colSpan="5"
                  style={{ background: '#F5F7FA', fontWeight: 'bold', fontSize: '20px' }}
                >
                  Види спорту осіб з інвалідністю з ураженнями опорно-рухового апарату
                </td>
              </tr>
              {arrRes &&
                arrRes['Види спорту осіб з інвалідністю з ураженнями опорно-рухового апарату'].map((el) => {
                    return (
                      <tr>
                        <td>{list[el.sportId]}</td>
                        <td>{el.athleteCategoryTitleId['МСУМК'] ? el.athleteCategoryTitleId['МСУМК'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['МСУ'] ? el.athleteCategoryTitleId['МСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗМСУ'] ? el.athleteCategoryTitleId['ЗМСУ'] : 0}</td>
                        <td>{el.athleteCategoryTitleId['ЗТУ'] ? el.athleteCategoryTitleId['ЗТУ']  : 0}</td>
                      </tr>
                    );
                })}

              
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default ReportPage;
