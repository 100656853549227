import React from 'react';

const Footer = () => {
  return (
    <>
      <div className="footer" style={{ fontSize: '12pt', lineHeight: '1.2' }}>
        <div className="footer-top" style={{ display: 'flex' }}>
          <img width="50px" height="50px" src="/Res/footer1.svg" alt="" />{' '}
          <div style={{ paddingLeft: '10px' }}>
            <h4 style={{ fontSize: '13.5pt' }}> Власність Міністерства молоді та спорту України</h4>
            <span>01601, м. Київ, вул. Еспланадна, 42</span>
          </div>
        </div>
        <div className='footerDiv'>
          Реалізовано Міністерством молоді та спорту України спільно з Міністерством цифрової трансформації України завдяки проєкту «Підтримка цифрової трансформації» за підтримки USAID та UK Dev
        </div>
        {/* <div style={{ color: 'red', width: '100%', display: 'flex' }}>
          <img width="50px" height="50px" src="/Res/footer2.svg" alt="" />{' '}
          <div style={{ paddingTop: '8px', paddingLeft: '10px' }}>
            Портал працює в тестовому режимі.{' '}
          </div>
        </div> */}

        {/* <div style={{'width':'80%','color':'#6D727C'}}> Спортивні організації, які знаходяться у цих областях та хочуть взяти участь у тестуванні онлайн-сервісу при подачі документів на присвоєння спортивних звань,можуть залишити заявку, заповнивши форму за  <a target="_blank" style={{'color':'#004BC1'}} href='https://docs.google.com/forms/d/e/1FAIpQLSeujUwXMQAnonuVXJNJ8Tuv21gcUC2LMxjUmxyA0iGBnDsK3w/viewform'>посиланням</a>.</div>
         */}

        <div className='footerDiv'>
          Для консультування з питань подачі заявок в онлайн сервісі звертатися за телефоном
          +380663820904 (Пн-Пт 09:00-18:00)
        </div>

        <div className='footerDiv' style={{ display: 'flex' }}>
          <img width="50px" height="50px" src="/Res/footer3.png" alt="" />{' '}
          <div style={{ paddingTop: '10px', paddingLeft: '10px' }}>
            Якщо ви маєте зауваження або пропозиції, будь ласка, напишіть нам:{' '}
            <a style={{ color: '#004BC1' }} href="mailto:sport.thedigital@gmail.com">
              sport.thedigital@gmail.com
            </a>
          </div>
        </div>
        {/* <div style={{ display: 'flex', height: 'max-content' }}> */}
          {/* <a href="https://technodreams.biz/ru/"><img src="/Res/logo.svg" alt="" /></a>  */}
          {/* <span style={{ alignSelf: 'center', margin: '0', color: '#6D727C' }}>
            {' '}
            Розробник порталу <br /> ФОП Крот С.А. (
            <a style={{ color: '#004BC1' }} href="https://technodreams.biz">
              TechnoDreams
            </a>
            ){' '}
          </span>
        </div> */}
      </div>
    </>
  );
};

export default Footer;
